@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,300italic,600,600italic");

/*
	Forty by HTML5 UP
	html5up.net | @ajlkn
	Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  -webkit-text-size-adjust: none;
}

mark {
  background-color: transparent;
  color: inherit;
}

input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input,
select,
textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
}

/* Basic */

@-ms-viewport {
  width: device-width;
}

body {
  -ms-overflow-style: scrollbar;
}

@media screen and (max-width: 480px) {
  html,
  body {
    min-width: 320px;
  }
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  background: #242943;
}

body.is-preload *,
body.is-preload *:before,
body.is-preload *:after {
  -moz-animation: none !important;
  -webkit-animation: none !important;
  -ms-animation: none !important;
  animation: none !important;
  -moz-transition: none !important;
  -webkit-transition: none !important;
  -ms-transition: none !important;
  transition: none !important;
}

/* Type */

body,
input,
select,
textarea {
  color: #ffffff;
  font-family: "Source Sans Pro", Helvetica, sans-serif;
  font-size: 14pt !important;
  font-weight: 300;
  letter-spacing: 0.025em;
  line-height: 1.65;
}

@media screen and (max-width: 1680px) {
  body,
  input,
  select,
  textarea {
    font-size: 14pt !important;
  }
}

a {
  -moz-transition: color 0.2s ease-in-out, border-bottom-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out,
    border-bottom-color 0.2s ease-in-out;
  -ms-transition: color 0.2s ease-in-out, border-bottom-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, border-bottom-color 0.2s ease-in-out;
  border-bottom: dotted 1px;
  color: inherit;
  text-decoration: none;
}

a:hover {
  border-bottom-color: transparent;
  color: #9bf1ff !important;
}

a:active {
  color: #53e3fb !important;
}

strong,
b {
  color: #ffffff;
  font-weight: 600;
}

em,
i {
  font-style: italic;
}

p {
  margin: 0 0 2em 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #ffffff;
  font-weight: 600;
  line-height: 1.65;
  margin: 0 0 1em 0;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
  border-bottom: 0;
}

h1 {
  font-size: 2.5em;
}

h2 {
  font-size: 1.75em;
}

h3 {
  font-size: 1.35em;
}

h4 {
  font-size: 1.1em;
}

h5 {
  font-size: 0.9em;
}

h6 {
  font-size: 0.7em;
}

@media screen and (max-width: 736px) {
  h1 {
    font-size: 2em;
  }

  h2 {
    font-size: 1.5em;
  }

  h3 {
    font-size: 1.25em;
  }
}

sub {
  font-size: 0.8em;
  position: relative;
  top: 0.5em;
}

sup {
  font-size: 0.8em;
  position: relative;
  top: -0.5em;
}

blockquote {
  border-left: solid 4px rgba(212, 212, 255, 0.1);
  font-style: italic;
  margin: 0 0 2em 0;
  padding: 0.5em 0 0.5em 2em;
}

code {
  background: rgba(212, 212, 255, 0.135);
  font-family: "Courier New", monospace;
  font-size: 0.9em;
  margin: 0 0.25em;
  padding: 0.25em 0.65em;
}

pre {
  -webkit-overflow-scrolling: touch;
  font-family: "Courier New", monospace;
  font-size: 0.9em;
  margin: 0 0 2em 0;
}

pre code {
  display: block;
  line-height: 1.75;
  padding: 1em 1.5em;
  overflow-x: auto;
}

hr {
  border: 0;
  border-bottom: solid 1px rgba(212, 212, 255, 0.1);
  margin: 2em 0;
}

hr.major {
  margin: 3em 0;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

/* Row */

.row {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  align-items: stretch;
}

.row > * {
  box-sizing: border-box;
}

.row.gtr-uniform > * > :last-child {
  margin-bottom: 0;
}

.row.aln-left {
  justify-content: flex-start;
}

.row.aln-center {
  justify-content: center;
}

.row.aln-right {
  justify-content: flex-end;
}

.row.aln-top {
  align-items: flex-start;
}

.row.aln-middle {
  align-items: center;
}

.row.aln-bottom {
  align-items: flex-end;
}

.row > .imp {
  order: -1;
}

.row > .col-1 {
  width: 8.33333%;
}

.row > .off-1 {
  margin-left: 8.33333%;
}

.row > .col-2 {
  width: 16.66667%;
}

.row > .off-2 {
  margin-left: 16.66667%;
}

.row > .col-3 {
  width: 25%;
}

.row > .off-3 {
  margin-left: 25%;
}

.row > .col-4 {
  width: 33.33333%;
}

.row > .off-4 {
  margin-left: 33.33333%;
}

.row > .col-5 {
  width: 41.66667%;
}

.row > .off-5 {
  margin-left: 41.66667%;
}

.row > .col-6 {
  width: 50%;
}

.row > .off-6 {
  margin-left: 50%;
}

.row > .col-7 {
  width: 58.33333%;
}

.row > .off-7 {
  margin-left: 58.33333%;
}

.row > .col-8 {
  width: 66.66667%;
}

.row > .off-8 {
  margin-left: 66.66667%;
}

.row > .col-9 {
  width: 75%;
}

.row > .off-9 {
  margin-left: 75%;
}

.row > .col-10 {
  width: 83.33333%;
}

.row > .off-10 {
  margin-left: 83.33333%;
}

.row > .col-11 {
  width: 91.66667%;
}

.row > .off-11 {
  margin-left: 91.66667%;
}

.row > .col-12 {
  width: 100%;
}

.row > .off-12 {
  margin-left: 100%;
}

.row.gtr-0 {
  margin-top: 0;
  margin-left: 0em;
}

.row.gtr-0 > * {
  padding: 0 0 0 0em;
}

.row.gtr-0.gtr-uniform {
  margin-top: 0em;
}

.row.gtr-0.gtr-uniform > * {
  padding-top: 0em;
}

.row.gtr-25 {
  margin-top: 0;
  margin-left: -0.5em;
}

.row.gtr-25 > * {
  padding: 0 0 0 0.5em;
}

.row.gtr-25.gtr-uniform {
  margin-top: -0.5em;
}

.row.gtr-25.gtr-uniform > * {
  padding-top: 0.5em;
}

.row.gtr-50 {
  margin-top: 0;
  margin-left: -1em;
}

.row.gtr-50 > * {
  padding: 0 0 0 1em;
}

.row.gtr-50.gtr-uniform {
  margin-top: -1em;
}

.row.gtr-50.gtr-uniform > * {
  padding-top: 1em;
}

.row {
  margin-top: 0;
  margin-left: -2em;
}

.row > * {
  padding: 0 0 0 2em;
}

.row.gtr-uniform {
  margin-top: -2em;
}

.row.gtr-uniform > * {
  padding-top: 2em;
}

.row.gtr-150 {
  margin-top: 0;
  margin-left: -3em;
}

.row.gtr-150 > * {
  padding: 0 0 0 3em;
}

.row.gtr-150.gtr-uniform {
  margin-top: -3em;
}

.row.gtr-150.gtr-uniform > * {
  padding-top: 3em;
}

.row.gtr-200 {
  margin-top: 0;
  margin-left: -4em;
}

.row.gtr-200 > * {
  padding: 0 0 0 4em;
}

.row.gtr-200.gtr-uniform {
  margin-top: -4em;
}

.row.gtr-200.gtr-uniform > * {
  padding-top: 4em;
}

@media screen and (max-width: 1680px) {
  .row {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    align-items: stretch;
  }

  .row > * {
    box-sizing: border-box;
  }

  .row.gtr-uniform > * > :last-child {
    margin-bottom: 0;
  }

  .row.aln-left {
    justify-content: flex-start;
  }

  .row.aln-center {
    justify-content: center;
  }

  .row.aln-right {
    justify-content: flex-end;
  }

  .row.aln-top {
    align-items: flex-start;
  }

  .row.aln-middle {
    align-items: center;
  }

  .row.aln-bottom {
    align-items: flex-end;
  }

  .row > .imp-xlarge {
    order: -1;
  }

  .row > .col-1-xlarge {
    width: 8.33333%;
  }

  .row > .off-1-xlarge {
    margin-left: 8.33333%;
  }

  .row > .col-2-xlarge {
    width: 16.66667%;
  }

  .row > .off-2-xlarge {
    margin-left: 16.66667%;
  }

  .row > .col-3-xlarge {
    width: 25%;
  }

  .row > .off-3-xlarge {
    margin-left: 25%;
  }

  .row > .col-4-xlarge {
    width: 33.33333%;
  }

  .row > .off-4-xlarge {
    margin-left: 33.33333%;
  }

  .row > .col-5-xlarge {
    width: 41.66667%;
  }

  .row > .off-5-xlarge {
    margin-left: 41.66667%;
  }

  .row > .col-6-xlarge {
    width: 50%;
  }

  .row > .off-6-xlarge {
    margin-left: 50%;
  }

  .row > .col-7-xlarge {
    width: 58.33333%;
  }

  .row > .off-7-xlarge {
    margin-left: 58.33333%;
  }

  .row > .col-8-xlarge {
    width: 66.66667%;
  }

  .row > .off-8-xlarge {
    margin-left: 66.66667%;
  }

  .row > .col-9-xlarge {
    width: 75%;
  }

  .row > .off-9-xlarge {
    margin-left: 75%;
  }

  .row > .col-10-xlarge {
    width: 83.33333%;
  }

  .row > .off-10-xlarge {
    margin-left: 83.33333%;
  }

  .row > .col-11-xlarge {
    width: 91.66667%;
  }

  .row > .off-11-xlarge {
    margin-left: 91.66667%;
  }

  .row > .col-12-xlarge {
    width: 100%;
  }

  .row > .off-12-xlarge {
    margin-left: 100%;
  }

  .row.gtr-0 {
    margin-top: 0;
    margin-left: 0em;
  }

  .row.gtr-0 > * {
    padding: 0 0 0 0em;
  }

  .row.gtr-0.gtr-uniform {
    margin-top: 0em;
  }

  .row.gtr-0.gtr-uniform > * {
    padding-top: 0em;
  }

  .row.gtr-25 {
    margin-top: 0;
    margin-left: -0.5em;
  }

  .row.gtr-25 > * {
    padding: 0 0 0 0.5em;
  }

  .row.gtr-25.gtr-uniform {
    margin-top: -0.5em;
  }

  .row.gtr-25.gtr-uniform > * {
    padding-top: 0.5em;
  }

  .row.gtr-50 {
    margin-top: 0;
    margin-left: -1em;
  }

  .row.gtr-50 > * {
    padding: 0 0 0 1em;
  }

  .row.gtr-50.gtr-uniform {
    margin-top: -1em;
  }

  .row.gtr-50.gtr-uniform > * {
    padding-top: 1em;
  }

  .row {
    margin-top: 0;
    margin-left: -2em;
  }

  .row > * {
    padding: 0 0 0 2em;
  }

  .row.gtr-uniform {
    margin-top: -2em;
  }

  .row.gtr-uniform > * {
    padding-top: 2em;
  }

  .row.gtr-150 {
    margin-top: 0;
    margin-left: -3em;
  }

  .row.gtr-150 > * {
    padding: 0 0 0 3em;
  }

  .row.gtr-150.gtr-uniform {
    margin-top: -3em;
  }

  .row.gtr-150.gtr-uniform > * {
    padding-top: 3em;
  }

  .row.gtr-200 {
    margin-top: 0;
    margin-left: -4em;
  }

  .row.gtr-200 > * {
    padding: 0 0 0 4em;
  }

  .row.gtr-200.gtr-uniform {
    margin-top: -4em;
  }

  .row.gtr-200.gtr-uniform > * {
    padding-top: 4em;
  }
}

@media screen and (max-width: 1280px) {
  .row {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    align-items: stretch;
  }

  .row > * {
    box-sizing: border-box;
  }

  .row.gtr-uniform > * > :last-child {
    margin-bottom: 0;
  }

  .row.aln-left {
    justify-content: flex-start;
  }

  .row.aln-center {
    justify-content: center;
  }

  .row.aln-right {
    justify-content: flex-end;
  }

  .row.aln-top {
    align-items: flex-start;
  }

  .row.aln-middle {
    align-items: center;
  }

  .row.aln-bottom {
    align-items: flex-end;
  }

  .row > .imp-large {
    order: -1;
  }

  .row > .col-1-large {
    width: 8.33333%;
  }

  .row > .off-1-large {
    margin-left: 8.33333%;
  }

  .row > .col-2-large {
    width: 16.66667%;
  }

  .row > .off-2-large {
    margin-left: 16.66667%;
  }

  .row > .col-3-large {
    width: 25%;
  }

  .row > .off-3-large {
    margin-left: 25%;
  }

  .row > .col-4-large {
    width: 33.33333%;
  }

  .row > .off-4-large {
    margin-left: 33.33333%;
  }

  .row > .col-5-large {
    width: 41.66667%;
  }

  .row > .off-5-large {
    margin-left: 41.66667%;
  }

  .row > .col-6-large {
    width: 50%;
  }

  .row > .off-6-large {
    margin-left: 50%;
  }

  .row > .col-7-large {
    width: 58.33333%;
  }

  .row > .off-7-large {
    margin-left: 58.33333%;
  }

  .row > .col-8-large {
    width: 66.66667%;
  }

  .row > .off-8-large {
    margin-left: 66.66667%;
  }

  .row > .col-9-large {
    width: 75%;
  }

  .row > .off-9-large {
    margin-left: 75%;
  }

  .row > .col-10-large {
    width: 83.33333%;
  }

  .row > .off-10-large {
    margin-left: 83.33333%;
  }

  .row > .col-11-large {
    width: 91.66667%;
  }

  .row > .off-11-large {
    margin-left: 91.66667%;
  }

  .row > .col-12-large {
    width: 100%;
  }

  .row > .off-12-large {
    margin-left: 100%;
  }

  .row.gtr-0 {
    margin-top: 0;
    margin-left: 0em;
  }

  .row.gtr-0 > * {
    padding: 0 0 0 0em;
  }

  .row.gtr-0.gtr-uniform {
    margin-top: 0em;
  }

  .row.gtr-0.gtr-uniform > * {
    padding-top: 0em;
  }

  .row.gtr-25 {
    margin-top: 0;
    margin-left: -0.375em;
  }

  .row.gtr-25 > * {
    padding: 0 0 0 0.375em;
  }

  .row.gtr-25.gtr-uniform {
    margin-top: -0.375em;
  }

  .row.gtr-25.gtr-uniform > * {
    padding-top: 0.375em;
  }

  .row.gtr-50 {
    margin-top: 0;
    margin-left: -0.75em;
  }

  .row.gtr-50 > * {
    padding: 0 0 0 0.75em;
  }

  .row.gtr-50.gtr-uniform {
    margin-top: -0.75em;
  }

  .row.gtr-50.gtr-uniform > * {
    padding-top: 0.75em;
  }

  .row {
    margin-top: 0;
    margin-left: -1.5em;
  }

  .row > * {
    padding: 0 0 0 1.5em;
  }

  .row.gtr-uniform {
    margin-top: -1.5em;
  }

  .row.gtr-uniform > * {
    padding-top: 1.5em;
  }

  .row.gtr-150 {
    margin-top: 0;
    margin-left: -2.25em;
  }

  .row.gtr-150 > * {
    padding: 0 0 0 2.25em;
  }

  .row.gtr-150.gtr-uniform {
    margin-top: -2.25em;
  }

  .row.gtr-150.gtr-uniform > * {
    padding-top: 2.25em;
  }

  .row.gtr-200 {
    margin-top: 0;
    margin-left: -3em;
  }

  .row.gtr-200 > * {
    padding: 0 0 0 3em;
  }

  .row.gtr-200.gtr-uniform {
    margin-top: -3em;
  }

  .row.gtr-200.gtr-uniform > * {
    padding-top: 3em;
  }
}

@media screen and (max-width: 980px) {
  .row {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    align-items: stretch;
  }

  .row > * {
    box-sizing: border-box;
  }

  .row.gtr-uniform > * > :last-child {
    margin-bottom: 0;
  }

  .row.aln-left {
    justify-content: flex-start;
  }

  .row.aln-center {
    justify-content: center;
  }

  .row.aln-right {
    justify-content: flex-end;
  }

  .row.aln-top {
    align-items: flex-start;
  }

  .row.aln-middle {
    align-items: center;
  }

  .row.aln-bottom {
    align-items: flex-end;
  }

  .row > .imp-medium {
    order: -1;
  }

  .row > .col-1-medium {
    width: 8.33333%;
  }

  .row > .off-1-medium {
    margin-left: 8.33333%;
  }

  .row > .col-2-medium {
    width: 16.66667%;
  }

  .row > .off-2-medium {
    margin-left: 16.66667%;
  }

  .row > .col-3-medium {
    width: 25%;
  }

  .row > .off-3-medium {
    margin-left: 25%;
  }

  .row > .col-4-medium {
    width: 33.33333%;
  }

  .row > .off-4-medium {
    margin-left: 33.33333%;
  }

  .row > .col-5-medium {
    width: 41.66667%;
  }

  .row > .off-5-medium {
    margin-left: 41.66667%;
  }

  .row > .col-6-medium {
    width: 50%;
  }

  .row > .off-6-medium {
    margin-left: 50%;
  }

  .row > .col-7-medium {
    width: 58.33333%;
  }

  .row > .off-7-medium {
    margin-left: 58.33333%;
  }

  .row > .col-8-medium {
    width: 66.66667%;
  }

  .row > .off-8-medium {
    margin-left: 66.66667%;
  }

  .row > .col-9-medium {
    width: 75%;
  }

  .row > .off-9-medium {
    margin-left: 75%;
  }

  .row > .col-10-medium {
    width: 83.33333%;
  }

  .row > .off-10-medium {
    margin-left: 83.33333%;
  }

  .row > .col-11-medium {
    width: 91.66667%;
  }

  .row > .off-11-medium {
    margin-left: 91.66667%;
  }

  .row > .col-12-medium {
    width: 100%;
  }

  .row > .off-12-medium {
    margin-left: 100%;
  }

  .row.gtr-0 {
    margin-top: 0;
    margin-left: 0em;
  }

  .row.gtr-0 > * {
    padding: 0 0 0 0em;
  }

  .row.gtr-0.gtr-uniform {
    margin-top: 0em;
  }

  .row.gtr-0.gtr-uniform > * {
    padding-top: 0em;
  }

  .row.gtr-25 {
    margin-top: 0;
    margin-left: -0.375em;
  }

  .row.gtr-25 > * {
    padding: 0 0 0 0.375em;
  }

  .row.gtr-25.gtr-uniform {
    margin-top: -0.375em;
  }

  .row.gtr-25.gtr-uniform > * {
    padding-top: 0.375em;
  }

  .row.gtr-50 {
    margin-top: 0;
    margin-left: -0.75em;
  }

  .row.gtr-50 > * {
    padding: 0 0 0 0.75em;
  }

  .row.gtr-50.gtr-uniform {
    margin-top: -0.75em;
  }

  .row.gtr-50.gtr-uniform > * {
    padding-top: 0.75em;
  }

  .row {
    margin-top: 0;
    margin-left: -1.5em;
  }

  .row > * {
    padding: 0 0 0 1.5em;
  }

  .row.gtr-uniform {
    margin-top: -1.5em;
  }

  .row.gtr-uniform > * {
    padding-top: 1.5em;
  }

  .row.gtr-150 {
    margin-top: 0;
    margin-left: -2.25em;
  }

  .row.gtr-150 > * {
    padding: 0 0 0 2.25em;
  }

  .row.gtr-150.gtr-uniform {
    margin-top: -2.25em;
  }

  .row.gtr-150.gtr-uniform > * {
    padding-top: 2.25em;
  }

  .row.gtr-200 {
    margin-top: 0;
    margin-left: -3em;
  }

  .row.gtr-200 > * {
    padding: 0 0 0 3em;
  }

  .row.gtr-200.gtr-uniform {
    margin-top: -3em;
  }

  .row.gtr-200.gtr-uniform > * {
    padding-top: 3em;
  }
}

@media screen and (max-width: 736px) {
  .row {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    align-items: stretch;
  }

  .row > * {
    box-sizing: border-box;
  }

  .row.gtr-uniform > * > :last-child {
    margin-bottom: 0;
  }

  .row.aln-left {
    justify-content: flex-start;
  }

  .row.aln-center {
    justify-content: center;
  }

  .row.aln-right {
    justify-content: flex-end;
  }

  .row.aln-top {
    align-items: flex-start;
  }

  .row.aln-middle {
    align-items: center;
  }

  .row.aln-bottom {
    align-items: flex-end;
  }

  .row > .imp-small {
    order: -1;
  }

  .row > .col-1-small {
    width: 8.33333%;
  }

  .row > .off-1-small {
    margin-left: 8.33333%;
  }

  .row > .col-2-small {
    width: 16.66667%;
  }

  .row > .off-2-small {
    margin-left: 16.66667%;
  }

  .row > .col-3-small {
    width: 25%;
  }

  .row > .off-3-small {
    margin-left: 25%;
  }

  .row > .col-4-small {
    width: 33.33333%;
  }

  .row > .off-4-small {
    margin-left: 33.33333%;
  }

  .row > .col-5-small {
    width: 41.66667%;
  }

  .row > .off-5-small {
    margin-left: 41.66667%;
  }

  .row > .col-6-small {
    width: 50%;
  }

  .row > .off-6-small {
    margin-left: 50%;
  }

  .row > .col-7-small {
    width: 58.33333%;
  }

  .row > .off-7-small {
    margin-left: 58.33333%;
  }

  .row > .col-8-small {
    width: 66.66667%;
  }

  .row > .off-8-small {
    margin-left: 66.66667%;
  }

  .row > .col-9-small {
    width: 75%;
  }

  .row > .off-9-small {
    margin-left: 75%;
  }

  .row > .col-10-small {
    width: 83.33333%;
  }

  .row > .off-10-small {
    margin-left: 83.33333%;
  }

  .row > .col-11-small {
    width: 91.66667%;
  }

  .row > .off-11-small {
    margin-left: 91.66667%;
  }

  .row > .col-12-small {
    width: 100%;
  }

  .row > .off-12-small {
    margin-left: 100%;
  }

  .row.gtr-0 {
    margin-top: 0;
    margin-left: 0em;
  }

  .row.gtr-0 > * {
    padding: 0 0 0 0em;
  }

  .row.gtr-0.gtr-uniform {
    margin-top: 0em;
  }

  .row.gtr-0.gtr-uniform > * {
    padding-top: 0em;
  }

  .row.gtr-25 {
    margin-top: 0;
    margin-left: -0.3125em;
  }

  .row.gtr-25 > * {
    padding: 0 0 0 0.3125em;
  }

  .row.gtr-25.gtr-uniform {
    margin-top: -0.3125em;
  }

  .row.gtr-25.gtr-uniform > * {
    padding-top: 0.3125em;
  }

  .row.gtr-50 {
    margin-top: 0;
    margin-left: -0.625em;
  }

  .row.gtr-50 > * {
    padding: 0 0 0 0.625em;
  }

  .row.gtr-50.gtr-uniform {
    margin-top: -0.625em;
  }

  .row.gtr-50.gtr-uniform > * {
    padding-top: 0.625em;
  }

  .row {
    margin-top: 0;
    margin-left: -1.25em;
  }

  .row > * {
    padding: 0 0 0 1.25em;
  }

  .row.gtr-uniform {
    margin-top: -1.25em;
  }

  .row.gtr-uniform > * {
    padding-top: 1.25em;
  }

  .row.gtr-150 {
    margin-top: 0;
    margin-left: -1.875em;
  }

  .row.gtr-150 > * {
    padding: 0 0 0 1.875em;
  }

  .row.gtr-150.gtr-uniform {
    margin-top: -1.875em;
  }

  .row.gtr-150.gtr-uniform > * {
    padding-top: 1.875em;
  }

  .row.gtr-200 {
    margin-top: 0;
    margin-left: -2.5em;
  }

  .row.gtr-200 > * {
    padding: 0 0 0 2.5em;
  }

  .row.gtr-200.gtr-uniform {
    margin-top: -2.5em;
  }

  .row.gtr-200.gtr-uniform > * {
    padding-top: 2.5em;
  }
}

@media screen and (max-width: 480px) {
  .row {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    align-items: stretch;
  }

  .row > * {
    box-sizing: border-box;
  }

  .row.gtr-uniform > * > :last-child {
    margin-bottom: 0;
  }

  .row.aln-left {
    justify-content: flex-start;
  }

  .row.aln-center {
    justify-content: center;
  }

  .row.aln-right {
    justify-content: flex-end;
  }

  .row.aln-top {
    align-items: flex-start;
  }

  .row.aln-middle {
    align-items: center;
  }

  .row.aln-bottom {
    align-items: flex-end;
  }

  .row > .imp-xsmall {
    order: -1;
  }

  .row > .col-1-xsmall {
    width: 8.33333%;
  }

  .row > .off-1-xsmall {
    margin-left: 8.33333%;
  }

  .row > .col-2-xsmall {
    width: 16.66667%;
  }

  .row > .off-2-xsmall {
    margin-left: 16.66667%;
  }

  .row > .col-3-xsmall {
    width: 25%;
  }

  .row > .off-3-xsmall {
    margin-left: 25%;
  }

  .row > .col-4-xsmall {
    width: 33.33333%;
  }

  .row > .off-4-xsmall {
    margin-left: 33.33333%;
  }

  .row > .col-5-xsmall {
    width: 41.66667%;
  }

  .row > .off-5-xsmall {
    margin-left: 41.66667%;
  }

  .row > .col-6-xsmall {
    width: 50%;
  }

  .row > .off-6-xsmall {
    margin-left: 50%;
  }

  .row > .col-7-xsmall {
    width: 58.33333%;
  }

  .row > .off-7-xsmall {
    margin-left: 58.33333%;
  }

  .row > .col-8-xsmall {
    width: 66.66667%;
  }

  .row > .off-8-xsmall {
    margin-left: 66.66667%;
  }

  .row > .col-9-xsmall {
    width: 75%;
  }

  .row > .off-9-xsmall {
    margin-left: 75%;
  }

  .row > .col-10-xsmall {
    width: 83.33333%;
  }

  .row > .off-10-xsmall {
    margin-left: 83.33333%;
  }

  .row > .col-11-xsmall {
    width: 91.66667%;
  }

  .row > .off-11-xsmall {
    margin-left: 91.66667%;
  }

  .row > .col-12-xsmall {
    width: 100%;
  }

  .row > .off-12-xsmall {
    margin-left: 100%;
  }

  .row.gtr-0 {
    margin-top: 0;
    margin-left: 0em;
  }

  .row.gtr-0 > * {
    padding: 0 0 0 0em;
  }

  .row.gtr-0.gtr-uniform {
    margin-top: 0em;
  }

  .row.gtr-0.gtr-uniform > * {
    padding-top: 0em;
  }

  .row.gtr-25 {
    margin-top: 0;
    margin-left: -0.3125em;
  }

  .row.gtr-25 > * {
    padding: 0 0 0 0.3125em;
  }

  .row.gtr-25.gtr-uniform {
    margin-top: -0.3125em;
  }

  .row.gtr-25.gtr-uniform > * {
    padding-top: 0.3125em;
  }

  .row.gtr-50 {
    margin-top: 0;
    margin-left: -0.625em;
  }

  .row.gtr-50 > * {
    padding: 0 0 0 0.625em;
  }

  .row.gtr-50.gtr-uniform {
    margin-top: -0.625em;
  }

  .row.gtr-50.gtr-uniform > * {
    padding-top: 0.625em;
  }

  .row {
    margin-top: 0;
    margin-left: -1.25em;
  }

  .row > * {
    padding: 0 0 0 1.25em;
  }

  .row.gtr-uniform {
    margin-top: -1.25em;
  }

  .row.gtr-uniform > * {
    padding-top: 1.25em;
  }

  .row.gtr-150 {
    margin-top: 0;
    margin-left: -1.875em;
  }

  .row.gtr-150 > * {
    padding: 0 0 0 1.875em;
  }

  .row.gtr-150.gtr-uniform {
    margin-top: -1.875em;
  }

  .row.gtr-150.gtr-uniform > * {
    padding-top: 1.875em;
  }

  .row.gtr-200 {
    margin-top: 0;
    margin-left: -2.5em;
  }

  .row.gtr-200 > * {
    padding: 0 0 0 2.5em;
  }

  .row.gtr-200.gtr-uniform {
    margin-top: -2.5em;
  }

  .row.gtr-200.gtr-uniform > * {
    padding-top: 2.5em;
  }
}

/* Section/Article */

section.special,
article.special {
  text-align: center;
}

header.major {
  width: -moz-max-content;
  width: -webkit-max-content;
  width: -ms-max-content;
  width: max-content;
  margin-bottom: 2em;
}

header.major > :first-child {
  margin-bottom: 0;
  width: calc(100% + 0.5em);
}

header.major > :first-child:after {
  content: "";
  background-color: #ffffff;
  display: block;
  height: 2px;
  margin: 0.325em 0 0.5em 0;
  width: 100%;
}

header.major > p {
  font-size: 0.7em;
  font-weight: 600;
  letter-spacing: 0.25em;
  margin-bottom: 0;
  text-transform: uppercase;
}

body.is-ie header.major > :first-child:after {
  max-width: 9em;
}

body.is-ie header.major > h1:after {
  max-width: 100% !important;
}

@media screen and (max-width: 736px) {
  header.major > p br {
    display: none;
  }
}

/* Form */

form {
  margin: 0 0 2em 0;
}

form > :last-child {
  margin-bottom: 0;
}

form > .fields {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: calc(100% + 3em);
  margin: -1.5em 0 2em -1.5em;
}

form > .fields > .field {
  -moz-flex-grow: 0;
  -webkit-flex-grow: 0;
  -ms-flex-grow: 0;
  flex-grow: 0;
  -moz-flex-shrink: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-shrink: 0;
  flex-shrink: 0;
  padding: 1.5em 0 0 1.5em;
  width: calc(100% - 1.5em);
}

form > .fields > .field.half {
  width: calc(50% - 0.75em);
}

form > .fields > .field.third {
  width: calc(100% / 3 - 0.5em);
}

form > .fields > .field.quarter {
  width: calc(25% - 0.375em);
}

@media screen and (max-width: 480px) {
  form > .fields {
    width: calc(100% + 3em);
    margin: -1.5em 0 2em -1.5em;
  }

  form > .fields > .field {
    padding: 1.5em 0 0 1.5em;
    width: calc(100% - 1.5em);
  }

  form > .fields > .field.half {
    width: calc(100% - 1.5em);
  }

  form > .fields > .field.third {
    width: calc(100% - 1.5em);
  }

  form > .fields > .field.quarter {
    width: calc(100% - 1.5em);
  }
}

label {
  color: #ffffff;
  display: block;
  font-size: 0.8em;
  font-weight: 600;
  letter-spacing: 0.25em;
  margin: 0 0 1em 0;
  text-transform: uppercase;
  display: inline;
}

input[type="text"],
input[type="number"],
input[type="date"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="search"],
input[type="url"],
select,
textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background: rgba(212, 212, 255, 0.135);
  border: none;
  border-radius: 0;
  color: inherit;
  display: block;
  outline: 0;
  padding: 0 1em;
  text-decoration: none;
  width: 100%;
}

input[type="text"]:invalid,
input[type="date"]:invalid,
input[type="password"]:invalid,
input[type="email"]:invalid,
input[type="tel"]:invalid,
input[type="search"]:invalid,
input[type="url"]:invalid,
select:invalid,
textarea:invalid {
  box-shadow: none;
}

input[type="text"]:focus,
input[type="date"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus,
input[type="search"]:focus,
input[type="url"]:focus,
select:focus,
textarea:focus {
  border-color: #9bf1ff;
  box-shadow: 0 0 0 2px #9bf1ff;
}

select {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' preserveAspectRatio='none' viewBox='0 0 40 40'%3E%3Cpath d='M9.4,12.3l10.4,10.4l10.4-10.4c0.2-0.2,0.5-0.4,0.9-0.4c0.3,0,0.6,0.1,0.9,0.4l3.3,3.3c0.2,0.2,0.4,0.5,0.4,0.9 c0,0.4-0.1,0.6-0.4,0.9L20.7,31.9c-0.2,0.2-0.5,0.4-0.9,0.4c-0.3,0-0.6-0.1-0.9-0.4L4.3,17.3c-0.2-0.2-0.4-0.5-0.4-0.9 c0-0.4,0.1-0.6,0.4-0.9l3.3-3.3c0.2-0.2,0.5-0.4,0.9-0.4S9.1,12.1,9.4,12.3z' fill='rgba(212, 212, 255, 0.1)' /%3E%3C/svg%3E");
  background-size: 1.25rem;
  background-repeat: no-repeat;
  background-position: calc(100% - 1rem) center;
  height: 2.75em;
  padding-right: 2.75em;
  text-overflow: ellipsis;
}

select option {
  color: #ffffff;
  background: #242943;
}

select:focus::-ms-value {
  background-color: transparent;
}

select::-ms-expand {
  display: none;
}

input[type="text"],
input[type="number"],
input[type="date"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="search"],
input[type="url"],
select {
  height: 2.75em;
}

textarea {
  padding: 0.75em 1em;
}

input[type="checkbox"],
input[type="radio"] {
  -moz-appearance: auto !important;
  -webkit-appearance: auto !important;
  -ms-appearance: auto !important;
  appearance: auto !important;
  display: block;
  float: left;
  margin-right: -2em;
  opacity: 0;
  width: 1em;
  z-index: -1;
}

input[type="checkbox"] + label,
input[type="radio"] + label {
  text-decoration: none;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-weight: 300;
  padding-left: 2.65em;
  padding-right: 0.75em;
  position: relative;
}

input[type="checkbox"] + label:before,
input[type="radio"] + label:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  text-transform: none !important;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

input[type="checkbox"] + label:before,
input[type="radio"] + label:before {
  background: rgba(212, 212, 255, 0.135);
  content: "";
  display: inline-block;
  font-size: 0.8em;
  height: 2.0625em;
  left: 0;
  letter-spacing: 0;
  line-height: 2.0625em;
  position: absolute;
  text-align: center;
  top: 0;
  width: 2.0625em;
}

input[type="checkbox"]:checked + label:before,
input[type="radio"]:checked + label:before {
  background: #ffffff;
  border-color: #9bf1ff;
  content: "\f00c";
  color: #242943;
}

input[type="checkbox"]:focus + label:before,
input[type="radio"]:focus + label:before {
  box-shadow: 0 0 0 2px #9bf1ff;
}

input[type="radio"] + label:before {
  border-radius: 100%;
}

::-webkit-input-placeholder {
  color: #fff !important;
  opacity: 1;
}

:-moz-placeholder {
  color: #fff !important;
  opacity: 1;
}

::-moz-placeholder {
  color: #fff !important;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #fff !important;
  opacity: 1;
}

/* Box */

.box {
  border: solid 1px rgba(212, 212, 255, 0.1);
  margin-bottom: 2em;
  padding: 1.5em;
}

.box > :last-child,
.box > :last-child > :last-child,
.box > :last-child > :last-child > :last-child {
  margin-bottom: 0;
}

.box.alt {
  border: 0;
  border-radius: 0;
  padding: 0;
}

/* Icon */

.icon {
  text-decoration: none;
  border-bottom: none;
  position: relative;
}

.icon:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  text-transform: none !important;
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}

.icon > .label {
  display: none;
}

.icon:before {
  line-height: inherit;
}

.icon.solid:before {
  font-weight: 900;
}

.icon.brands:before {
  font-family: "Font Awesome 5 Brands";
}

.icon.alt:before {
  background-color: #ffffff;
  border-radius: 100%;
  color: #242943;
  display: inline-block;
  height: 2em;
  line-height: 2em;
  text-align: center;
  width: 2em;
}

a.icon.alt:before {
  -moz-transition: background-color 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s ease-in-out;
  -ms-transition: background-color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out;
}

a.icon.alt:hover:before {
  background-color: #6fc3df;
}

a.icon.alt:active:before {
  background-color: #37a6cb;
}

/* Image */

.image {
  border: 0;
  display: inline-block;
  position: relative;
}

.image img {
  display: block;
}

.image.left,
.image.right {
  max-width: 30%;
}

.image.left img,
.image.right img {
  width: 100%;
}

.image.left {
  float: left;
  margin: 0 1.5em 1.25em 0;
  top: 0.25em;
}

.image.right {
  float: right;
  margin: 0 0 1.25em 1.5em;
  top: 0.25em;
}

.image.fit {
  display: block;
  margin: 0 0 2em 0;
  width: 100%;
}

.image.fit img {
  width: 100%;
}

.image.main {
  display: block;
  margin: 2.5em 0;
  width: 100%;
}

.image.main img {
  width: 100%;
}

@media screen and (max-width: 736px) {
  .image.main {
    margin: 1.5em 0;
  }
}

/* List */

ol {
  list-style: decimal;
  margin: 0 0 2em 0;
  padding-left: 1.25em;
}

ol li {
  padding-left: 0.25em;
}

ul {
  list-style: disc;
  margin: 0 0 2em 0;
  padding-left: 1em;
}

ul li {
  padding-left: 0.5em;
}

ul.alt {
  list-style: none;
  padding-left: 0;
}

ul.alt li {
  border-top: solid 1px rgba(212, 212, 255, 0.1);
  padding: 0.5em 0;
}

ul.alt li:first-child {
  border-top: 0;
  padding-top: 0;
}

dl {
  margin: 0 0 2em 0;
}

dl dt {
  display: block;
  font-weight: 600;
  margin: 0 0 1em 0;
}

dl dd {
  margin-left: 2em;
}

/* Actions */

ul.actions {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  cursor: default;
  list-style: none;
  margin-left: -1em;
  padding-left: 0;
}

ul.actions li {
  padding: 0 0 0 1em;
  vertical-align: middle;
}

ul.actions.special {
  -moz-justify-content: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  width: 100%;
  margin-left: 0;
}

ul.actions.special li:first-child {
  padding-left: 0;
}

ul.actions.stacked {
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-left: 0;
}

ul.actions.stacked li {
  padding: 1.3em 0 0 0;
}

ul.actions.stacked li:first-child {
  padding-top: 0;
}

ul.actions.fit {
  width: calc(100% + 1em);
}

ul.actions.fit li {
  -moz-flex-grow: 1;
  -webkit-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
  -moz-flex-shrink: 1;
  -webkit-flex-shrink: 1;
  -ms-flex-shrink: 1;
  flex-shrink: 1;
  width: 100%;
}

ul.actions.fit li > * {
  width: 100%;
}

ul.actions.fit.stacked {
  width: 100%;
}

/* Icons */

ul.icons {
  cursor: default;
  list-style: none;
  padding-left: 0;
}

ul.icons li {
  display: inline-block;
  padding: 0 1em 0 0;
}

ul.icons li:last-child {
  padding-right: 0;
}

@media screen and (max-width: 736px) {
  ul.icons li {
    padding: 0 0.75em 0 0;
  }
}

/* Pagination */

ul.pagination {
  cursor: default;
  list-style: none;
  padding-left: 0;
}

ul.pagination li {
  display: inline-block;
  padding-left: 0;
  vertical-align: middle;
}

ul.pagination li > .page {
  -moz-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  -ms-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  border-bottom: 0;
  display: inline-block;
  font-size: 0.8em;
  font-weight: 600;
  height: 1.5em;
  line-height: 1.5em;
  margin: 0 0.125em;
  min-width: 1.5em;
  padding: 0 0.5em;
  text-align: center;
}

ul.pagination li > .page.active {
  background-color: #ffffff;
  color: #242943;
}

ul.pagination li > .page.active:hover {
  background-color: #9bf1ff;
  color: #242943 !important;
}

ul.pagination li > .page.active:active {
  background-color: #53e3fb;
}

ul.pagination li:first-child {
  padding-right: 0.75em;
}

ul.pagination li:last-child {
  padding-left: 0.75em;
}

@media screen and (max-width: 480px) {
  ul.pagination li:nth-child(n + 2):nth-last-child(n + 2) {
    display: none;
  }

  ul.pagination li:first-child {
    padding-right: 0;
  }
}

/* Table */

.table-wrapper {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
}

table {
  margin: 0 0 2em 0;
  width: 100%;
}

table tbody tr {
  border: solid 1px rgba(212, 212, 255, 0.1);
  border-left: 0;
  border-right: 0;
}

table tbody tr:nth-child(2n + 1) {
  background-color: rgba(212, 212, 255, 0.135);
}

table td {
  padding: 0.75em 0.75em;
}

table th {
  color: #ffffff;
  font-size: 0.9em;
  font-weight: 600;
  padding: 0 0.75em 0.75em 0.75em;
  text-align: left;
}

table thead {
  border-bottom: solid 2px rgba(212, 212, 255, 0.1);
}

table tfoot {
  border-top: solid 2px rgba(212, 212, 255, 0.1);
}

table.alt {
  border-collapse: separate;
}

table.alt tbody tr td {
  border: solid 1px rgba(212, 212, 255, 0.1);
  border-left-width: 0;
  border-top-width: 0;
}

table.alt tbody tr td:first-child {
  border-left-width: 1px;
}

table.alt tbody tr:first-child td {
  border-top-width: 1px;
}

table.alt thead {
  border-bottom: 0;
}

table.alt tfoot {
  border-top: 0;
}

/* Button */

input[type="submit"],
input[type="reset"],
input[type="button"],
button,
.button {
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  -moz-transition: background-color 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out, color 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out, color 0.2s ease-in-out;
  -ms-transition: background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out,
    color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out,
    color 0.2s ease-in-out;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  box-shadow: inset 0 0 0 2px #ffffff;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-size: 0.8em;
  font-weight: 600;
  height: 3.5em;
  letter-spacing: 0.25em;
  line-height: 3.5em;
  padding: 0 1.75em;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
}

input[type="submit"]:hover,
input[type="submit"]:active,
input[type="reset"]:hover,
input[type="reset"]:active,
input[type="button"]:hover,
input[type="button"]:active,
button:hover,
button:active,
.button:hover,
.button:active {
  box-shadow: inset 0 0 0 2px #9bf1ff;
  color: #9bf1ff;
}

input[type="submit"]:active,
input[type="reset"]:active,
input[type="button"]:active,
button:active,
.button:active {
  background-color: rgba(155, 241, 255, 0.1);
  box-shadow: inset 0 0 0 2px #53e3fb;
  color: #53e3fb;
}

input[type="submit"].icon:before,
input[type="reset"].icon:before,
input[type="button"].icon:before,
button.icon:before,
.button.icon:before {
  margin-right: 0.5em;
}

input[type="submit"].fit,
input[type="reset"].fit,
input[type="button"].fit,
button.fit,
.button.fit {
  width: 100%;
}

input[type="submit"].small,
input[type="reset"].small,
input[type="button"].small,
button.small,
.button.small {
  font-size: 0.6em;
}

input[type="submit"].large,
input[type="reset"].large,
input[type="button"].large,
button.large,
.button.large {
  font-size: 1.25em;
  height: 3em;
  line-height: 3em;
}

input[type="submit"].next,
input[type="reset"].next,
input[type="button"].next,
button.next,
.button.next {
  padding-right: 4.5em;
  position: relative;
}

input[type="submit"].next:before,
input[type="submit"].next:after,
input[type="reset"].next:before,
input[type="reset"].next:after,
input[type="button"].next:before,
input[type="button"].next:after,
button.next:before,
button.next:after,
.button.next:before,
.button.next:after {
  -moz-transition: opacity 0.2s ease-in-out;
  -webkit-transition: opacity 0.2s ease-in-out;
  -ms-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
  background-position: center right;
  background-repeat: no-repeat;
  background-size: 36px 24px;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  right: 1.5em;
  top: 0;
  vertical-align: middle;
  width: 36px;
}

input[type="submit"].next:before,
input[type="reset"].next:before,
input[type="button"].next:before,
button.next:before,
.button.next:before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='36px' height='24px' viewBox='0 0 36 24' zoomAndPan='disable'%3E%3Cstyle%3Eline %7B stroke: %23ffffff%3B stroke-width: 2px%3B %7D%3C/style%3E%3Cline x1='0' y1='12' x2='34' y2='12' /%3E%3Cline x1='25' y1='4' x2='34' y2='12.5' /%3E%3Cline x1='25' y1='20' x2='34' y2='11.5' /%3E%3C/svg%3E");
}

input[type="submit"].next:after,
input[type="reset"].next:after,
input[type="button"].next:after,
button.next:after,
.button.next:after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='36px' height='24px' viewBox='0 0 36 24' zoomAndPan='disable'%3E%3Cstyle%3Eline %7B stroke: %239bf1ff%3B stroke-width: 2px%3B %7D%3C/style%3E%3Cline x1='0' y1='12' x2='34' y2='12' /%3E%3Cline x1='25' y1='4' x2='34' y2='12.5' /%3E%3Cline x1='25' y1='20' x2='34' y2='11.5' /%3E%3C/svg%3E");
  opacity: 0;
  z-index: 1;
}

input[type="submit"].next:hover:after,
input[type="submit"].next:active:after,
input[type="reset"].next:hover:after,
input[type="reset"].next:active:after,
input[type="button"].next:hover:after,
input[type="button"].next:active:after,
button.next:hover:after,
button.next:active:after,
.button.next:hover:after,
.button.next:active:after {
  opacity: 1;
}

@media screen and (max-width: 1280px) {
  input[type="submit"].next,
  input[type="reset"].next,
  input[type="button"].next,
  button.next,
  .button.next {
    padding-right: 5em;
  }
}

input[type="submit"].primary,
input[type="reset"].primary,
input[type="button"].primary,
button.primary,
.button.primary {
  background-color: #ffffff;
  box-shadow: none;
  color: #242943;
}

input[type="submit"].primary:hover,
input[type="submit"].primary:active,
input[type="reset"].primary:hover,
input[type="reset"].primary:active,
input[type="button"].primary:hover,
input[type="button"].primary:active,
button.primary:hover,
button.primary:active,
.button.primary:hover,
.button.primary:active {
  background-color: #9bf1ff;
  color: #242943 !important;
}

input[type="submit"].primary:active,
input[type="reset"].primary:active,
input[type="button"].primary:active,
button.primary:active,
.button.primary:active {
  background-color: #53e3fb;
}

input[type="submit"].disabled,
input[type="submit"]:disabled,
input[type="reset"].disabled,
input[type="reset"]:disabled,
input[type="button"].disabled,
input[type="button"]:disabled,
button.disabled,
button:disabled,
.button.disabled,
.button:disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.25;
}

/* Tiles */

.tiles {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border-top: 0 !important;
}

.tiles + * {
  border-top: 0 !important;
}

.tiles article {
  -moz-align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-transition: -moz-transform 0.25s ease, opacity 0.25s ease,
    -moz-filter 1s ease, -webkit-filter 1s ease;
  -webkit-transition: -webkit-transform 0.25s ease, opacity 0.25s ease,
    -webkit-filter 1s ease, -webkit-filter 1s ease;
  -ms-transition: -ms-transform 0.25s ease, opacity 0.25s ease,
    -ms-filter 1s ease, -webkit-filter 1s ease;
  transition: transform 0.25s ease, opacity 0.25s ease, filter 1s ease,
    -webkit-filter 1s ease;
  padding: 4em 4em 2em 4em;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: default;
  height: 40vh;
  max-height: 40em;
  min-height: 24em;
  overflow: hidden;
  position: relative;
  width: 50%;
}

.tiles article .image {
  display: none;
}

.tiles article header {
  position: relative;
  z-index: 3;
}

.tiles article h3 {
  font-size: 1.75em;
}

.tiles article h3 a:hover {
  color: inherit !important;
}

.tiles article .link.primary {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 4;
}

.tiles article:before {
  -moz-transition: opacity 0.5s ease;
  -webkit-transition: opacity 0.5s ease;
  -ms-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
  bottom: 0;
  content: "";
  display: block;
  height: 100%;
  left: 0;
  opacity: 0.85;
  position: absolute;
  width: 100%;
  z-index: 2;
}

.tiles article:after {
  background-color: rgba(36, 41, 67, 0.25);
  content: "";
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.tiles article:hover:before {
  opacity: 0;
}

.tiles article.is-transitioning {
  -moz-transform: scale(0.95);
  -webkit-transform: scale(0.95);
  -ms-transform: scale(0.95);
  transform: scale(0.95);
  -moz-filter: blur(0.5em);
  -webkit-filter: blur(0.5em);
  -ms-filter: blur(0.5em);
  filter: blur(0.5em);
  opacity: 0;
}

.tiles article:nth-child(4n - 1),
.tiles article:nth-child(4n - 2) {
  width: 50%;
}

.tiles article:nth-child(6n - 5):before {
  background-color: #6fc3df;
}

.tiles article:nth-child(6n - 4):before {
  background-color: #8d82c4;
}

.tiles article:nth-child(6n - 3):before {
  background-color: #ec8d81;
}

.tiles article:nth-child(6n - 2):before {
  background-color: #8ea9e8;
}

.tiles article:nth-child(6n - 1):before {
  background-color: #e7b788;
}

.tiles article:nth-child(6n):before {
  background-color: #87c5a4;
}

@media screen and (max-width: 1280px) {
  .tiles article {
    padding: 4em 3em 2em 3em;
    height: 30vh;
    max-height: 30em;
    min-height: 20em;
  }
}

@media screen and (max-width: 980px) {
  .tiles article {
    width: 50% !important;
  }
}

@media screen and (max-width: 736px) {
  .tiles article {
    padding: 3em 1.5em 1em 1.5em;
    height: 16em;
    max-height: none;
    min-height: 0;
  }

  .tiles article h3 {
    font-size: 1.5em;
  }
}

@media screen and (max-width: 480px) {
  .tiles {
    display: block;
  }

  .tiles article {
    height: 20em;
    width: 100% !important;
  }
}

/* Contact Method */

.contact-method {
  margin: 0 0 2em 0;
  padding-left: 3.25em;
  position: relative;
}

.contact-method .icon {
  left: 0;
  position: absolute;
  top: 0;
}

.contact-method h3 {
  margin: 0 0 0.5em 0;
}

/* Spotlights */

.spotlights {
  border-top: 0 !important;
}

.spotlights + * {
  border-top: 0 !important;
}

.spotlights > section {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-direction: row;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  background-color: #2e3450;
}

.spotlights > section > .image {
  background-position: center center;
  background-size: cover;
  border-radius: 0;
  display: block;
  position: relative;
  width: 30%;
}

.spotlights > section > .image img {
  border-radius: 0;
  display: block;
  width: 100%;
}

.spotlights > section > .image:before {
  background: rgba(36, 41, 67, 0.9);
  content: "";
  display: block;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.spotlights > section > .content {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -moz-justify-content: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -moz-align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  padding: 2em 3em 0.1em 3em;
  width: 70%;
}

.spotlights > section > .content > .inner {
  margin: 0 auto;
  max-width: 100%;
  width: 65em;
}

.spotlights > section:nth-child(2n) {
  -moz-flex-direction: row-reverse;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  background-color: #333856;
}

.spotlights > section:nth-child(2n) > .content {
  -moz-align-items: -moz-flex-end;
  -webkit-align-items: -webkit-flex-end;
  -ms-align-items: -ms-flex-end;
  align-items: flex-end;
}

@media screen and (max-width: 1680px) {
  .spotlights > section > .image {
    width: 40%;
  }

  .spotlights > section > .content {
    width: 60%;
  }
}

@media screen and (max-width: 1280px) {
  .spotlights > section > .image {
    width: 45%;
  }

  .spotlights > section > .content {
    width: 55%;
  }
}

@media screen and (max-width: 980px) {
  .spotlights > section {
    display: block;
  }

  .spotlights > section > .image {
    width: 100%;
  }

  .spotlights > section > .content {
    padding: 4em 3em 2em 3em;
    width: 100%;
  }
}

@media screen and (max-width: 736px) {
  .spotlights > section > .content {
    padding: 3em 1.5em 1em 1.5em;
  }
}

/* Header */

@-moz-keyframes reveal-header {
  0% {
    top: -4em;
    opacity: 0;
  }

  100% {
    top: 0;
    opacity: 1;
  }
}

@-webkit-keyframes reveal-header {
  0% {
    top: -4em;
    opacity: 0;
  }

  100% {
    top: 0;
    opacity: 1;
  }
}

@-ms-keyframes reveal-header {
  0% {
    top: -4em;
    opacity: 0;
  }

  100% {
    top: 0;
    opacity: 1;
  }
}

@keyframes reveal-header {
  0% {
    top: -4em;
    opacity: 0;
  }

  100% {
    top: 0;
    opacity: 1;
  }
}

#header {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  background-color: #2a2f4a;
  box-shadow: 0 0 0.25em 0 rgba(0, 0, 0, 0.15);
  cursor: default;
  font-weight: 600;
  height: 3.25em;
  left: 0;
  letter-spacing: 0.25em;
  line-height: 3.25em;
  margin: 0;
  position: fixed;
  text-transform: uppercase;
  top: 0;
  width: 100%;
  z-index: 8;
}

#header .logo {
  border: 0;
  display: inline-block;
  font-size: 0.8em;
  height: inherit;
  line-height: inherit;
  padding: 0 1.5em;
}

#header .logo strong {
  -moz-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  -ms-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  background-color: #ffffff;
  color: #242943;
  display: inline-block;
  line-height: 1.65em;
  margin-right: 0.325em;
  padding: 0 0.125em 0 0.375em;
}

#header .logo:hover strong {
  background-color: #9bf1ff;
}

#header .logo:active strong {
  background-color: #53e3fb;
}

#header nav {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-justify-content: -moz-flex-end;
  -webkit-justify-content: -webkit-flex-end;
  -ms-justify-content: -ms-flex-end;
  justify-content: flex-end;
  -moz-flex-grow: 1;
  -webkit-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
  height: inherit;
  line-height: inherit;
}

#header nav a {
  border: 0;
  display: block;
  font-size: 0.8em;
  height: inherit;
  line-height: inherit;
  padding: 0 0.75em;
  position: relative;
  vertical-align: middle;
}

#header nav a:last-child {
  padding-right: 1.5em;
}

#header nav span.menu {
  padding-right: 3.325em !important;
  cursor: pointer;
}

#header nav span.menu:hover {
  border-bottom-color: transparent;
  color: #9bf1ff !important;
}

#header nav span.menu:before,
#header nav span.menu:after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='32' viewBox='0 0 24 32' preserveAspectRatio='none'%3E%3Cstyle%3Eline %7B stroke-width: 2px%3B stroke: %23ffffff%3B %7D%3C/style%3E%3Cline x1='0' y1='11' x2='24' y2='11' /%3E%3Cline x1='0' y1='21' x2='24' y2='21' /%3E%3Cline x1='0' y1='16' x2='24' y2='16' /%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 24px 32px;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  right: 1.5em;
  top: 0;
  vertical-align: middle;
  width: 24px;
}

#header nav span.menu:after {
  -moz-transition: opacity 0.2s ease-in-out;
  -webkit-transition: opacity 0.2s ease-in-out;
  -ms-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='32' viewBox='0 0 24 32' preserveAspectRatio='none'%3E%3Cstyle%3Eline %7B stroke-width: 2px%3B stroke: %239bf1ff%3B %7D%3C/style%3E%3Cline x1='0' y1='11' x2='24' y2='11' /%3E%3Cline x1='0' y1='21' x2='24' y2='21' /%3E%3Cline x1='0' y1='16' x2='24' y2='16' /%3E%3C/svg%3E");
  opacity: 0;
  z-index: 1;
}

#header nav span.menu:hover:after,
#header nav span.menu:active:after {
  opacity: 1;
}

#header nav span.menu:last-child {
  padding-right: 3.875em !important;
}

#header nav span.menu:last-child:before,
#header nav span.menu:last-child:after {
  right: 2em;
}

#header.reveal {
  -moz-animation: reveal-header 0.35s ease;
  -webkit-animation: reveal-header 0.35s ease;
  -ms-animation: reveal-header 0.35s ease;
  animation: reveal-header 0.35s ease;
}

#header.alt {
  -moz-transition: opacity 2.5s ease;
  -webkit-transition: opacity 2.5s ease;
  -ms-transition: opacity 2.5s ease;
  transition: opacity 2.5s ease;
  -moz-transition-delay: 0.75s;
  -webkit-transition-delay: 0.75s;
  -ms-transition-delay: 0.75s;
  transition-delay: 0.75s;
  -moz-animation: none;
  -webkit-animation: none;
  -ms-animation: none;
  animation: none;
  background-color: transparent;
  box-shadow: none;
  position: absolute;
}

#header.alt.style1 .logo strong {
  color: #6fc3df;
}

#header.alt.style2 .logo strong {
  color: #8d82c4;
}

#header.alt.style3 .logo strong {
  color: #ec8d81;
}

#header.alt.style4 .logo strong {
  color: #e7b788;
}

#header.alt.style5 .logo strong {
  color: #8ea9e8;
}

#header.alt.style6 .logo strong {
  color: #87c5a4;
}

body.is-preload #header.alt {
  opacity: 0;
}

@media screen and (max-width: 1680px) {
  #header nav span.menu {
    padding-right: 3.75em !important;
  }

  #header nav span.menu:last-child {
    padding-right: 4.25em !important;
  }
}

@media screen and (max-width: 1280px) {
  #header nav span.menu {
    padding-right: 4em !important;
  }

  #header nav span.menu:last-child {
    padding-right: 4.5em !important;
  }
}

@media screen and (max-width: 736px) {
  #header {
    height: 2.75em;
    line-height: 2.75em;
  }

  #header .logo {
    padding: 0 1em;
  }

  #header nav a {
    padding: 0 0.5em;
  }

  #header nav a:last-child {
    padding-right: 1em;
  }

  #header nav span.menu {
    padding-right: 3.25em !important;
  }

  #header nav span.menu:before,
  #header nav span.menu:after {
    right: 0.75em;
  }

  #header nav span.menu:last-child {
    padding-right: 4em !important;
  }

  #header nav span.menu:last-child:before,
  #header nav span.menu:last-child:after {
    right: 1.5em;
  }
}

@media screen and (max-width: 480px) {
  #header .logo span {
    display: none;
  }

  #header nav span.menu {
    overflow: hidden;
    padding-right: 0 !important;
    text-indent: 5em;
    white-space: nowrap;
    width: 5em;
  }

  #header nav span.menu:before,
  #header nav span.menu:after {
    right: 0;
    width: inherit;
  }

  #header nav span.menu:last-child:before,
  #header nav span.menu:last-child:after {
    width: 4em;
    right: 0;
  }
}

/* Banner */

#banner {
  -moz-align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  background-image: url("./images/banner.jpg");
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  padding: 6em 0 2em 0;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom: 0 !important;
  cursor: default;
  height: 60vh;
  margin-bottom: -3.25em;
  max-height: 32em;
  min-height: 22em;
  position: relative;
  top: -3.25em;
}

#banner:after {
  -moz-transition: opacity 2.5s ease;
  -webkit-transition: opacity 2.5s ease;
  -ms-transition: opacity 2.5s ease;
  transition: opacity 2.5s ease;
  -moz-transition-delay: 0.75s;
  -webkit-transition-delay: 0.75s;
  -ms-transition-delay: 0.75s;
  transition-delay: 0.75s;
  pointer-events: none;
  background-color: #242943;
  content: "";
  display: block;
  height: 100%;
  left: 0;
  opacity: 0.85;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

#banner h1 {
  font-size: 3.25em;
}

#banner > .inner {
  -moz-transition: opacity 1.5s ease, -moz-transform 0.5s ease-out,
    -moz-filter 0.5s ease, -webkit-filter 0.5s ease;
  -webkit-transition: opacity 1.5s ease, -webkit-transform 0.5s ease-out,
    -webkit-filter 0.5s ease, -webkit-filter 0.5s ease;
  -ms-transition: opacity 1.5s ease, -ms-transform 0.5s ease-out,
    -ms-filter 0.5s ease, -webkit-filter 0.5s ease;
  transition: opacity 1.5s ease, transform 0.5s ease-out, filter 0.5s ease,
    -webkit-filter 0.5s ease;
  padding: 0 !important;
  position: relative;
  z-index: 2;
}

#banner > .inner .image {
  display: none;
}

#banner > .inner header {
  width: auto;
}

#banner > .inner header > :first-child {
  width: auto;
}

#banner > .inner header > :first-child:after {
  max-width: 100%;
}

#banner > .inner .content {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  margin: 0 0 2em 0;
}

#banner > .inner .content > * {
  margin-right: 1.5em;
  margin-bottom: 0;
}

#banner > .inner .content > :last-child {
  margin-right: 0;
}

#banner > .inner .content p {
  font-size: 0.8em;
  font-weight: 600;
  letter-spacing: 0.25em;
  text-transform: uppercase;
}

#banner.major {
  height: 75vh;
  min-height: 30em;
  max-height: 50em;
}

#banner.major.alt {
  opacity: 0.75;
}

#banner.style1:after {
  background-color: #6fc3df;
}

#banner.style2:after {
  background-color: #8d82c4;
}

#banner.style3:after {
  background-color: #ec8d81;
}

#banner.style4:after {
  background-color: #e7b788;
}

#banner.style5:after {
  background-color: #8ea9e8;
}

#banner.style6:after {
  background-color: #87c5a4;
}

body.is-preload #banner:after {
  opacity: 1;
}

body.is-preload #banner > .inner {
  -moz-filter: blur(0.125em);
  -webkit-filter: blur(0.125em);
  -ms-filter: blur(0.125em);
  filter: blur(0.125em);
  -moz-transform: translateX(-0.5em);
  -webkit-transform: translateX(-0.5em);
  -ms-transform: translateX(-0.5em);
  transform: translateX(-0.5em);
  opacity: 0;
}

@media screen and (max-width: 1280px) {
  #banner {
    background-attachment: scroll;
  }
}

@media screen and (max-width: 736px) {
  #banner {
    padding: 5em 0 1em 0;
    height: auto;
    margin-bottom: -2.75em;
    max-height: none;
    min-height: 0;
    top: -2.75em;
  }

  #banner h1 {
    font-size: 2em;
  }

  #banner > .inner .content {
    display: block;
  }

  #banner > .inner .content > * {
    margin-right: 0;
    margin-bottom: 2em;
  }

  #banner.major {
    height: auto;
    min-height: 0;
    max-height: none;
  }
}

@media screen and (max-width: 480px) {
  #banner {
    padding: 6em 0 2em 0;
  }

  #banner > .inner .content p br {
    display: none;
  }

  #banner.major {
    padding: 8em 0 4em 0;
  }
}

/* Main */

#main {
  background-color: #2a2f4a;
}

#main > * {
  border-top: solid 1px rgba(212, 212, 255, 0.1);
}

#main > *:first-child {
  border-top: 0;
}

#main > * > .inner {
  padding: 4em 0 2em 0;
  margin: 0 auto;
  max-width: 65em;
  width: calc(100% - 6em);
}

@media screen and (max-width: 736px) {
  #main > * > .inner {
    padding: 3em 0 1em 0;
    width: calc(100% - 3em);
  }
}

#main.alt {
  background-color: transparent;
  border-bottom: solid 1px rgba(212, 212, 255, 0.1);
}

/* Contact */

#contact {
  border-bottom: solid 1px rgba(212, 212, 255, 0.1);
  overflow-x: hidden;
}

#contact > .inner {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  padding: 0 !important;
}

#contact > .inner > :nth-child(2n - 1) {
  padding: 4em 3em 2em 0;
  border-right: solid 1px rgba(212, 212, 255, 0.1);
  width: 60%;
}

#contact > .inner > :nth-child(2n) {
  padding-left: 3em;
  width: 40%;
}

#contact > .inner > .split {
  padding: 0;
}

#contact > .inner > .split > * {
  padding: 3em 0 1em 3em;
  position: relative;
}

#contact > .inner > .split > *:before {
  border-top: solid 1px rgba(212, 212, 255, 0.1);
  content: "";
  display: block;
  margin-left: -3em;
  position: absolute;
  top: 0;
  width: calc(100vw + 3em);
}

#contact > .inner > .split > :first-child:before {
  display: none;
}

@media screen and (max-width: 980px) {
  #contact > .inner {
    display: block;
  }

  #contact > .inner > :nth-child(2n - 1) {
    padding: 4em 0 2em 0;
    border-right: 0;
    width: 100%;
  }

  #contact > .inner > :nth-child(2n) {
    padding-left: 0;
    width: 100%;
  }

  #contact > .inner > .split > * {
    padding: 3em 0 1em 0;
  }

  #contact > .inner > .split > :first-child:before {
    display: block;
  }
}

@media screen and (max-width: 736px) {
  #contact > .inner > :nth-child(2n - 1) {
    padding: 3em 0 1em 0;
  }
}

/* Footer */

#footer .copyright {
  font-size: 0.8em;
  list-style: none;
  padding-left: 0;
}

#footer .copyright li {
  border-left: solid 1px rgba(212, 212, 255, 0.1);
  color: rgba(244, 244, 255, 0.2);
  display: inline-block;
  line-height: 1;
  margin-left: 1em;
  padding-left: 1em;
}

#footer .copyright li:first-child {
  border-left: 0;
  margin-left: 0;
  padding-left: 0;
}

@media screen and (max-width: 480px) {
  #footer .copyright li {
    display: block;
    border-left: 0;
    margin-left: 0;
    padding-left: 0;
    line-height: inherit;
  }
}

/* Wrapper */

#wrapper {
  -moz-transition: -moz-filter 0.35s ease, -webkit-filter 0.35s ease,
    opacity 0.375s ease-out;
  -webkit-transition: -webkit-filter 0.35s ease, -webkit-filter 0.35s ease,
    opacity 0.375s ease-out;
  -ms-transition: -ms-filter 0.35s ease, -webkit-filter 0.35s ease,
    opacity 0.375s ease-out;
  transition: filter 0.35s ease, -webkit-filter 0.35s ease,
    opacity 0.375s ease-out;
  padding-top: 3.25em;
}

#wrapper.is-transitioning {
  opacity: 0;
}

#wrapper > * > .inner {
  padding: 4em 0 2em 0;
  margin: 0 auto;
  max-width: 65em;
  width: calc(100% - 6em);
}

@media screen and (max-width: 736px) {
  #wrapper > * > .inner {
    padding: 3em 0 1em 0;
    width: calc(100% - 3em);
  }
}

@media screen and (max-width: 736px) {
  #wrapper {
    padding-top: 2.75em;
  }
}

/* Menu */

#menu {
  -moz-transition: -moz-transform 0.35s ease, opacity 0.35s ease,
    visibility 0.35s;
  -webkit-transition: -webkit-transform 0.35s ease, opacity 0.35s ease,
    visibility 0.35s;
  -ms-transition: -ms-transform 0.35s ease, opacity 0.35s ease, visibility 0.35s;
  transition: transform 0.35s ease, opacity 0.35s ease, visibility 0.35s;
  -moz-align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-justify-content: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  pointer-events: none;
  background: rgba(36, 41, 67, 0.9);
  box-shadow: none;
  height: 100%;
  left: 0;
  opacity: 0;
  overflow: hidden;
  padding: 3em 2em;
  position: fixed;
  top: 0;
  visibility: hidden;
  width: 100%;
  z-index: 9;
}

#menu .inner {
  -moz-transition: -moz-transform 0.35s ease-out, opacity 0.35s ease,
    visibility 0.35s;
  -webkit-transition: -webkit-transform 0.35s ease-out, opacity 0.35s ease,
    visibility 0.35s;
  -ms-transition: -ms-transform 0.35s ease-out, opacity 0.35s ease,
    visibility 0.35s;
  transition: transform 0.35s ease-out, opacity 0.35s ease, visibility 0.35s;
  -moz-transform: rotateX(20deg);
  -webkit-transform: rotateX(20deg);
  -ms-transform: rotateX(20deg);
  transform: rotateX(20deg);
  -webkit-overflow-scrolling: touch;
  max-width: 100%;
  max-height: 100vh;
  opacity: 0;
  overflow: auto;
  text-align: center;
  visibility: hidden;
  width: 18em;
}

#menu .inner > :first-child {
  margin-top: 2em;
}

#menu .inner > :last-child {
  margin-bottom: 3em;
}

#menu ul {
  margin: 0 0 1em 0;
}

#menu ul.links {
  list-style: none;
  padding: 0;
}

#menu ul.links > li {
  padding: 0;
}

#menu ul.links > li > a:not(.button) {
  border: 0;
  border-top: solid 1px rgba(212, 212, 255, 0.1);
  display: block;
  font-size: 0.8em;
  font-weight: 600;
  letter-spacing: 0.25em;
  line-height: 4em;
  text-decoration: none;
  text-transform: uppercase;
}

#menu ul.links > li > .button {
  display: block;
  margin: 0.5em 0 0 0;
}

#menu ul.links > li:first-child > a:not(.button) {
  border-top: 0 !important;
}

#menu .close {
  -moz-transition: color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out;
  -ms-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  border: 0;
  cursor: pointer;
  display: block;
  height: 4em;
  line-height: 4em;
  overflow: hidden;
  padding-right: 1.25em;
  position: absolute;
  right: 0;
  text-align: right;
  text-indent: 8em;
  top: 0;
  vertical-align: middle;
  white-space: nowrap;
  width: 8em;
}

#menu .close:before,
#menu .close:after {
  -moz-transition: opacity 0.2s ease-in-out;
  -webkit-transition: opacity 0.2s ease-in-out;
  -ms-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
  background-position: center;
  background-repeat: no-repeat;
  content: "";
  display: block;
  height: 4em;
  position: absolute;
  right: 0;
  top: 0;
  width: 4em;
}

#menu .close:before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='20px' height='20px' viewBox='0 0 20 20' zoomAndPan='disable'%3E%3Cstyle%3Eline %7B stroke: %23ffffff%3B stroke-width: 2%3B %7D%3C/style%3E%3Cline x1='0' y1='0' x2='20' y2='20' /%3E%3Cline x1='20' y1='0' x2='0' y2='20' /%3E%3C/svg%3E");
}

#menu .close:after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='20px' height='20px' viewBox='0 0 20 20' zoomAndPan='disable'%3E%3Cstyle%3Eline %7B stroke: %239bf1ff%3B stroke-width: 2%3B %7D%3C/style%3E%3Cline x1='0' y1='0' x2='20' y2='20' /%3E%3Cline x1='20' y1='0' x2='0' y2='20' /%3E%3C/svg%3E");
  opacity: 0;
}

#menu .close:hover:after,
#menu .close:active:after {
  opacity: 1;
}

body.is-ie #menu {
  background: rgba(42, 47, 74, 0.975);
}

body.is-menu-visible #wrapper {
  -moz-filter: blur(0.5em);
  -webkit-filter: blur(0.5em);
  -ms-filter: blur(0.5em);
  filter: blur(0.5em);
}

body.is-menu-visible #menu {
  pointer-events: auto;
  opacity: 1;
  visibility: visible;
}

body.is-menu-visible #menu .inner {
  -moz-transform: none;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  opacity: 1;
  visibility: visible;
}
.slider-wrapper {
  position: relative;
  width: 100%;
  margin-top: 20px;
}

.slider-values {
  width: 100%;
  position: relative;
  margin: auto;
  color: #ffffff;
}

.slider-container {
  position: relative;
  width: 100%;
  height: 30px;
}

input[type="range"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  outline: none;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  background-color: transparent;
  pointer-events: none;
}

.slider-track {
  width: 100%;
  height: 5px;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  border-radius: 5px;
}

input[type="range"]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  height: 5px;
}

input[type="range"]::-moz-range-track {
  -moz-appearance: none;
  height: 5px;
}

input[type="range"]::-ms-track {
  appearance: none;
  height: 5px;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 1.5em;
  width: 1.5em;
  background-color: #242943;
  border: 3px solid #ffffff;
  cursor: pointer;
  margin-top: -12px;
  pointer-events: auto;
  border-radius: 50%;
}

input[type="range"]::-moz-range-thumb {
  -webkit-appearance: none;
  height: 1.5em;
  width: 1.5em;
  cursor: pointer;
  border-radius: 50%;
  background-color: #242943;
  border: 3px solid #ffffff;
  pointer-events: auto;
}

input[type="range"]::-ms-thumb {
  appearance: none;
  height: 1.5em;
  width: 1.5em;
  cursor: pointer;
  border-radius: 50%;
  background-color: #242943;
  border: 3px solid #ffffff;
  pointer-events: auto;
}

input[type="range"]:active::-webkit-slider-thumb {
  box-shadow: inset 0 0 0 3px #9bf1ff;
  color: #9bf1ff;
  border: none;
}

#search {
  margin-left: 20px;
}

.contributor-label-container {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin: 0.5em 0;
}

.contributor-container {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin: 0.5em 0;
}

.w-30 {
  width: 30% !important;
}

.w-55 {
  width: 55% !important;
}

.c-p {
  cursor: pointer !important;
}

.txt-align-right {
  text-align: right;
}

.img-preview-container {
  width: 280px;
  margin: 0 0.5em;
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.img-preview-container figcaption {
  position: relative;
  top: -10px;
}

.img-preview-container .image-credit {
  height: 1.75em !important;
  padding: 0 0.25em !important;
}

body.is-preload #banner:after {
  opacity: 0.85;
}

body.is-preload #banner > .inner {
  -moz-filter: none;
  -webkit-filter: none;
  -ms-filter: none;
  filter: none;
  -moz-transform: none;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  opacity: 1;
}

.react-select__control {
  background: rgba(212, 212, 255, 0.135) !important;
  border: none !important;
  box-shadow: none !important;
}

.react-select__placeholder {
  color: #ffffff !important;
}

.react-select__input {
  color: #ffffff !important;
  width: auto !important;
  height: 30px !important;
  box-shadow: none !important;
}

.react-select__menu {
  border: none !important;
}

.react-select__menu-list {
  background: #242943 !important;
}

.react-select__option {
  background-color: #242943 !important;
  color: #ffffff !important;
}

.react-select__single-value {
  color: #ffffff !important;
}
.react-select__multi-value,
.react-select__multi-value__label,
.react-select__multi-value__remove {
  background-color: #242943 !important;
  color: #ffffff !important;
}

.react-select__multi-value__remove:hover {
  cursor: pointer;
}

.react-select__option--is-focused,
.react-select__option:hover {
  background-color: #31344f !important;
}
