.select-item {
  background-color: #242943;
  color: #ffffff;
  border: 0 solid #d0d0d0;
  cursor: pointer;
  margin: 0 3px 3px 0;
  padding: 2px 6px;
}

.slideshow-container {
  max-width: 400px;
  height: 225px;
  position: relative;
  margin: auto;
}

.img-container {
  display: none;
  width: 100%;
  height: 100%;
}

.img-container img {
  width: 100%;
  height: 100%;
}

.img-back,
.img-forward {
  cursor: pointer;
  position: absolute;
  top: 40%;
  width: auto;
  padding: 10px;
  color: #2a2e49;
  font-size: 19px;
  border-radius: 0 5px 5px 0;
  background-color: rgba(255, 255, 255, 0.5);
}

.img-forward {
  right: 0;
  border-radius: 5px 0 0 5px;
}

.img-back:hover,
.img-forward:hover {
  color: rgba(255, 255, 255, 0.5) !important;
  background-color: rgba(42, 46, 73, 0.5);
}

.img-credit {
  color: #2a2e49;
  font-size: 16px;
  font-weight: bold;
  padding: 5px 14px;
  position: absolute;
  bottom: 0px;
  width: 100%;
  text-align: left;
  background-color: rgba(255, 255, 255, 0.5);
}

.img-message-info {
  color: #2a2e49;
  font-size: 14px;
  font-weight: bold;
  padding: 10px 14px;
  position: absolute;
  top: 0;
  background-color: rgba(255, 255, 255, 0.5);
}
