.col-12 {
  margin-top: 10px;
}

ol {
  max-height: 310px;
  overflow: auto;
}

.fit {
  width: 100%;
  overflow: hidden;
}

.fit li {
  margin-bottom: 0.5em;
}

.d-none {
  display: none;
}

.text-ellipsis--4 {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  white-space: normal;
}
