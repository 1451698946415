.modal {
  display: none;
  position: fixed;
  z-index: 10;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  position: relative;
  background-color: #242943;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  width: 70%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
}

.modal-content .img-preview-container {
  width: 235px;
}

.modal-content .img-preview-container figcaption {
  top: -15px;
}

@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }

  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }

  to {
    top: 0;
    opacity: 1;
  }
}

.modal-header {
  padding: 2px 16px;
  min-height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.modal-body {
  padding: 2px 16px;
  min-height: 100px;
}

.modal-footer {
  display: flex;
  justify-content: space-between;
  padding: 2px 16px;
  margin: 0.5em;
  min-height: 70px;
}
