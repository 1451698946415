.tree,
.subtree {
  list-style-type: none;
  position: relative;
}

.subtree {
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  margin-bottom: 0;
}

.subtree li {
  position: relative;
}

.tree-toggle-label {
  user-select: none;
  cursor: pointer;
}

.tree-toggle-label:before {
  content: "\f067" !important;
  margin-left: -8px;
}

.tree-toggle {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.tree-toggle:checked + .tree-toggle-label:before {
  content: "\f068" !important;
}

.tree-toggle + .tree-toggle-label + .subtree {
  display: none;
}

.tree-toggle:checked + .tree-toggle-label + .subtree {
  display: block;
}

.t3-list {
  padding-left: 2em;
}

.t3-list li {
  font-size: 0.8em;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 0.25em;
  margin-bottom: 1em;
  position: relative;
}
