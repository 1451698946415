table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

th {
  white-space: nowrap;
}

th,
td {
  text-align: left !important;
  padding: 8px !important;
  min-width: 200px;
}

.data-cell {
  display: -webkit-box;
  width: 200px;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
  -webkit-line-clamp: 1;
}

.tab {
  overflow: hidden;
}

.tab button {
  box-shadow: none;
  transition: 0.3s;
}

.tab button:hover {
  color: #53e3fb;
}

.tab button.active {
  border: 1px solid #ccc;
  border-bottom: none;
  color: #53e3fb;
  background: #242943;
}

.tabcontent {
  padding: 6px 12px;
  border: 1px solid #ccc;
  margin-top: -2px;
}

.list-header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.list-header > button {
  margin-right: 2em;
}

.header-cell {
  position: relative;
  cursor: pointer;
}

.header-cell::before,
.header-cell::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 12px;
  border: 4px solid transparent;
}

.header-cell::before {
  border-bottom-color: #bdbdbd;
  margin-top: -8px;
}

.header-cell::after {
  border-top-color: #bdbdbd;
  margin-top: 1px;
}

.header-cell.sort-asc::before {
  border-width: 6px;
  margin-top: -9px;
  right: 10px;
}

.header-cell.sort-asc::after {
  content: none;
}

.header-cell.sort-desc::before {
  content: none;
}

.header-cell.sort-desc::after {
  border-width: 6px;
  margin-top: -2px;
  right: 10px;
}

.pagination {
  display: flex;
  justify-content: flex-end;
  height: 36px;
  margin: 0;
  padding: 0;
  margin-right: 1rem;
}

.pagination ul {
  padding: 0;
  display: inline-block;
  *display: inline;
  margin-bottom: 0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.pagination li {
  display: inline;
}

.pagination a {
  float: left;
  padding: 0 12px;
  line-height: 30px;
  text-decoration: none;
  border: 1px solid #ddd;
  border-left-width: 0;
}

.pagination .active a,
.pagination a:hover {
  background-color: rgba(212, 212, 255, 0.135);
  color: #fff !important;
  border-bottom-color: #ddd;
}

.pagination .disabled a,
.pagination .disabled a:hover,
.pagination .disabled span {
  color: #94999e !important;
  background-color: transparent;
  cursor: default;
}

.pagination li:first-child a,
.pagination li:first-child span {
  border-left-width: 1px;
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
}

.pagination li:last-child a {
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
}

.pagination li.active,
.pagination li.disabled {
  text-decoration: none;
}

.pagination li:first-child {
  border-left-width: 1px;
  padding: 0 !important;
}

.pagination li:last-child {
  padding: 0 !important;
}
